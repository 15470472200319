@import '../../global/variables.css';

#notification-icon {
  cursor: pointer;
  position: relative;
  padding: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

#notification-icon.active {
  background-color: var(--noti-app-btn-active-color);
}

#tooltip {
  display: none;
  margin: 8px;
  min-height: 250px;
  border: 1px solid rgba(0,0,0,.2);
  background-color: white;
  border-radius: 0;
  max-width: none;
}

#tooltip[data-show] {
  display: flex;
  justify-content: center;
}
.iconContainer{
  width: 20px;
  height: 20px;
}

.notification-count.badge {
  position: absolute;
  right: 0.75rem;
  top: 0.75rem;
  border-radius: 0.75rem;
  outline: 1px solid white;
  font-variant-numeric: lining-nums tabular-nums;
  font-size: 0.625rem;
  line-height: 1.125rem; /* 180% */
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
  justify-content: center;
}