import React, { useEffect, useState } from 'react';
import { Row, Col } from 'src';

import * as Styled from './FullModal.styles';

const FullModal = ({show, children, actionButton}) => {
  const [showTransition, setShowTransition] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const ActionButtonComponent = () => <Styled.ActionButton onClick={() => actionButton?.onClick()}>
        {actionButton?.label || 'Accept'}
      </Styled.ActionButton>

  useEffect(() => {
    if(show) {
      setShowModal(true);
      setTimeout(() => setShowTransition(true), 50)
    } else if (!show && showModal) {
      setShowTransition(false)
      setTimeout(() => setShowModal(false), 350)
    }
  }, [show]);

  return (
    <>
      { showModal && 
        <Styled.Background
          initialstyle={!showTransition}
          showtransition={showTransition}
          >
            <Styled.ContentWrapper>
              <Row>
                <Col xs={12}>
                <Styled.Content>
                  {children}
                  { actionButton?.onClick && <ActionButtonComponent />}
                </Styled.Content>
                </Col>
              </Row>
            </Styled.ContentWrapper>
        </Styled.Background>
      }
    </>
  )
}

export default FullModal;