import { Component, Prop, State, h, Listen, Fragment, Element } from '@stencil/core';
import { NotificationService } from '../../services/notification-service';
import { SearchFilterInterface, IConfigData, SubscriptionChannelsProps } from './notification.interface';
import { createPopper, Instance, Placement } from '@popperjs/core';
import "@bcg-web/bcg-notification-component";
import IsExpired from '../../utils/isExpired';

@Component({
  tag: 'notification-view',
  styleUrl: 'notification-view.css',
  shadow: true,
})
export class NotificationViewComponent {
  private _envConfig: IConfigData;
  private _defaultFilter: Array<string> = ['*'];
  private intervalFn: NodeJS.Timer;

  @Prop() accessToken: string;
  @Prop() userEmail: string;
  @Prop() filterFields: string;
  @Prop() showFilter: boolean = true;
  @Prop() removeMsg: boolean = true;
  @Prop() showRefresh: boolean = false;
  @Prop() showHeader: boolean = true;
  @Prop() headerTitle: string = 'Notifications';
  @Prop() cookieName: string = 'accessToken';
  @Prop() publisher: string;
  @Prop() maxHeight: string = '400px';
  @Prop() pollingInterval: number = 20000;
  @Prop() limit: number = 10;
  @Prop() isShowAction: boolean = true;
  @Prop() placement: Placement = 'bottom';
  @Prop() maxWidth: string = '400px';
  @Prop() fullScreen: boolean = false;
  @Prop() allowPin: boolean = false;
  @Prop() filterType: string = 'category';
  @Prop() removeType: string = 'cross-icon';
  @Prop() showExpiryBadge: boolean = false;
  @Prop() showSubTitle: boolean = true;
  @Prop() environment: 'integration' | 'preproduction' | 'production' = 'preproduction';
  @Prop() apiKey: string;
  @Prop() hrId: string;
  @Prop() designVariation: string = 'BCG_design_2';
  /** show image */
  @Prop() showIcon: boolean = false;
  /** enable read/unread */
  @Prop() enableReadUnread: boolean = true;
  /** show badges */
  @Prop() badges: string = 'publisher,category';
  /** allow settings */
  @Prop() enableSettings: boolean = false;
  /** close notification */
  @Prop() enableClose: boolean = false;

   /** allows to pass the which all channel user can opt-in/opt-out */
  @Prop() subscriptionChannels: SubscriptionChannelsProps | string = { inboxEnabled: { displayName: 'via KN' }, emailEnabled: { displayName: 'via Email' } }
  /** preferences title */
  @Prop() preferenceTitle: string;
  @Prop() metrics: string;

  @State() notificationData: SearchFilterInterface;
  @State() notificationCount: number = 0;
  @State() isLoading: boolean;

  @State()
  popover: Instance;

  @State()
  open: boolean;

  @State()
  loading: boolean;

  @State()
  error: string;

  @Element() el: HTMLElement;

  private button: HTMLSpanElement;
  private tooltip: HTMLDivElement;

  private showPopper() {
    if (this.popover) {
      this.tooltip.setAttribute('data-show', '');
      this.open = true;
      this.popover.update();
      this.notificationCount = 0;
    }
  }

  private hidePopper() {
    if (this.tooltip) {
      this.open = false;
    }
  }

  private handleClick = () => {
    if (!this.popover) {
      this.popover = createPopper(this.button, this.tooltip, {
        placement: this.placement,
        modifiers: [
          {
            name: 'computeStyles',
            options: {
              gpuAcceleration: false,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ],
      });
    }

    if (this.tooltip.hasAttribute('data-show')) {
      this.hidePopper();
    } else {
      this.showPopper();
    }
  };

  @Listen('click', { target: 'window' })
  clickListener(event: Event) {
    if (!this.el.contains(event.target as HTMLElement)) {
      this.hidePopper();
    }
  }

  @Listen('closePopper')
  clickClosePopperListener() {
    this.hidePopper();
  }

  getCookie(key) {
    let matchedCookie = document.cookie.match('(^|;)\\s*' + key + '\\s*=\\s*([^;]+)');
    return matchedCookie ? matchedCookie.pop() : '';
  }

  throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function () {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(lastFunc);
        lastFunc = setTimeout(function () {
          if (Date.now() - lastRan >= limit) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, limit - (Date.now() - lastRan));
      }
    };
  };
  debounce = (func, wait) => {
    let timeout;

    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };

      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };

  componentWillLoad() {
    this._envConfig = {
      apiHost: `https://api-private.${this.environment}.smp-paas.com`,
      apiKey: this.apiKey,
      psid: ''
    };
    if (this._envConfig) {
      const cookieAccessToken = this.getCookie(`${this.cookieName}`);
      NotificationService.saveUserInfo(cookieAccessToken ? cookieAccessToken : this.accessToken, this.userEmail, this._envConfig, this.publisher);
      this.fetchNotifications(this._defaultFilter);
      this.intervalFn = setInterval(() => {
        this.fetchNotifications(this._defaultFilter)
      }, this.pollingInterval);

      // const sleep = duration => new Promise(resolve => setTimeout(resolve, duration))
      // const poll = (promiseFn, duration) => promiseFn().then(
      //   sleep(duration).then(() => poll(promiseFn, duration)))
      // poll(() => new Promise(() => this.fetchNotifications(this._defaultFilter)), this.pollingInterval)
    }
  }

  disconnectedCallback(): void {
    clearInterval(this.intervalFn);
  }

  async fetchNotifications(category) {
    this.isLoading = true;
    this.notificationData = await NotificationService.getData(category);
    let notifications = this.notificationData?.notifications ?? [];
    notifications = notifications.filter(x => !IsExpired(x));
    this.notificationCount = notifications?.length || 0;
    this.isLoading = false;
    if (this.open) {
      setTimeout(() => {
        this.notificationCount = 0;
      }, 3000)
    }
  }

  render() {
    return (
      <Fragment>
        {/* <div class="iconContainer"> */}
        <div
          class={`btn btn-action ${this.open ? 'active' : 'none'}`}
          id="notification-icon"
          aria-describedby="tooltip"
          onClick={this.handleClick}
          ref={el => (this.button = el as HTMLSpanElement)}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <g clip-path="url(#clip0_2334_335)">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.24804 1.32054C9.24934 1.29719 9.25 1.27367 9.25 1.25C9.25 0.559644 8.69036 0 8 0C7.30964 0 6.75 0.559644 6.75 1.25C6.75 1.27367 6.75066 1.29718 6.75196 1.32053C5.89824 1.53471 5.10904 1.96704 4.47186 2.58461C3.53199 3.49557 2.99997 4.73561 2.99997 6.03317C2.99997 8.38142 2.48186 9.84161 2.00232 10.6937C1.76148 11.1217 1.52636 11.4035 1.36176 11.5719C1.27929 11.6562 1.2141 11.7126 1.17418 11.7448C1.15423 11.7609 1.14142 11.7707 1.13508 11.7752C1.13507 11.7752 1.13508 11.7752 1.13508 11.7752L1.13273 11.7768L1.13032 11.7784C1.13032 11.7784 1.13191 11.7775 1.13508 11.7752L1.13731 11.7738C1.13731 11.7738 1.13816 11.7732 1.49997 12.3332L1.13816 11.7732C1.13713 11.7739 1.1361 11.7746 1.13508 11.7752C0.888993 11.9362 0.777275 12.2395 0.860543 12.5218C0.944158 12.8053 1.20443 12.9998 1.49997 12.9998H14.5C14.7955 12.9998 15.0558 12.8053 15.1394 12.5218C15.2222 12.241 15.1122 11.9395 14.8689 11.7779L14.8657 11.7756C14.8594 11.771 14.8457 11.7609 14.8258 11.7448C14.7858 11.7126 14.7207 11.6562 14.6382 11.5719C14.4736 11.4035 14.2385 11.1217 13.9976 10.6937C13.5181 9.84161 13 8.38142 13 6.03317C13 4.73561 12.468 3.49557 11.5281 2.58461C10.8909 1.96705 10.1017 1.53473 9.24804 1.32054ZM7.98236 2.49988C7.98823 2.49996 7.99411 2.5 8 2.5C8.00588 2.5 8.01176 2.49996 8.01763 2.49988C8.99027 2.50438 9.9181 2.881 10.6001 3.54203C11.2856 4.20638 11.6666 5.10291 11.6666 6.03317C11.6666 8.58492 12.2319 10.2747 12.8357 11.3476C12.8995 11.461 12.9635 11.5672 13.0272 11.6665H2.97275C3.03641 11.5672 3.10048 11.461 3.16429 11.3476C3.76808 10.2747 4.33331 8.58492 4.33331 6.03317C4.33331 5.10291 4.71439 4.20638 5.39983 3.54203C6.08186 2.88099 7.0097 2.50437 7.98236 2.49988Z" fill="#323232"/>
              <path d="M6.09849 13.9678C6.39241 13.7461 6.81045 13.8046 7.0322 14.0985C7.10741 14.1982 7.23042 14.2997 7.40444 14.3768C7.57802 14.4537 7.78396 14.4976 8 14.4976C8.21604 14.4976 8.42198 14.4537 8.59556 14.3768C8.76958 14.2997 8.89259 14.1982 8.9678 14.0985C9.18955 13.8046 9.60759 13.7461 9.90151 13.9678C10.1954 14.1896 10.2539 14.6076 10.0322 14.9015C9.80253 15.2059 9.48795 15.4398 9.13547 15.5959C8.78255 15.7522 8.39155 15.831 8 15.831C7.60845 15.831 7.21745 15.7522 6.86453 15.5959C6.51205 15.4398 6.19747 15.2059 5.9678 14.9015C5.74606 14.6076 5.80456 14.1896 6.09849 13.9678Z" fill="#323232"/>
            </g>
            <defs>
              <clipPath id="clip0_2334_335">
                <rect width="16" height="16" fill="white"/>
              </clipPath>
            </defs>
          </svg>

          {this.notificationCount > 0 &&
            <span class="notification-count badge badge-danger badge-rounded">{this.notificationCount}</span>
          }
        </div>
        {/* </div> */}
        <div id="tooltip" role="tooltip" class="popover" data-show={this.open} ref={el => (this.tooltip = el as HTMLDivElement)}>
          <bcg-notification-component
            apiKey={this.apiKey}
            environment={this.environment}
            publisher={this.publisher}
            userEmail={this.userEmail}
            accessToken={this.accessToken}
            headerTitle={this.headerTitle}
            limit={this.limit}
            maxHeight={this.maxHeight}
            maxWidth={this.maxWidth}
            pollingInterval={this.pollingInterval}
            filterFields={this.filterFields}
            filterType={this.filterType}
            showFilter={this.showFilter}
            fullScreen={this.fullScreen}
            removeType={this.removeType}
            showExpiryBadge={this.showExpiryBadge}
            showSubTitle={this.showSubTitle}
            allowPin={this.allowPin}
            removeMsg={this.removeMsg}
            showRefresh={this.showRefresh}
            showHeader={this.showHeader}
            isShowAction={this.isShowAction}
            designVariation={this.designVariation}
            hrId={this.hrId}
            showIcon={this.showIcon}
            enableReadUnread={this.enableReadUnread}
            enableClose={this.enableClose}
            enableSettings={this.enableSettings}
            badges={this.badges}
            togglePopper={this.open}
            subscriptionChannels={this.subscriptionChannels}
            inModal={true}
            preference-title={this.preferenceTitle}
            metrics={this.metrics}
          />
        </div>
      </Fragment>
    );
  }
}
