import React, { useEffect, useState } from 'react';
import moment from 'moment';

import axios from '../../axios-instance';
import { SUBSCRIBERS } from '../../subscribers';
import { OktaKeepAliveSubject } from '../../subject';

export const OKTA_SESSION_EXPIRATION = 'okta-session-expiration';

let attempts = 0;
const KeepAlive = ({ oktaUrl, children, verbose }) => {
  const oneMinute = 60000;
  const [url, setUrl] = useState();

  const callOktaSession = async () => {
    verbose && console.log('callOktaSession');
    const sessionId = await getOktaSessionId();
    verbose && console.log('sessionId', sessionId);
    if (sessionId) {
      const refreshOccurred = await refreshOktaSession(sessionId);
      verbose && console.log('refreshOccurred', refreshOccurred)
      if (refreshOccurred) {
        OktaKeepAliveSubject.next({ type: SUBSCRIBERS.ONSUCCESS, data: { expiresAt: localStorage.getItem(OKTA_SESSION_EXPIRATION) } });
      } else {
        OktaKeepAliveSubject.next({ type: SUBSCRIBERS.ONERROR, data: { error: 'refresh failed. See console logs.' } });
      }
    } else {
      OktaKeepAliveSubject.next({ type: SUBSCRIBERS.ONERROR, data: { error: 'no session id found from okta. Is the user logged in?' } });
    }
    verbose && console.timeEnd('okta-keep-alive');
  }

  const getOktaSessionId = async () => {
    verbose && console.log('step 1 getOktaSession');
    try {
      const response = await axios.get(`${url}/api/v1/sessions/me`, {
        headers: {
          Accept: 'application/json',
          "Content-Type": 'application/json',
          Authorization: null
        },
        withCredentials: true
      });

      verbose && console.log(response);
      return response.data.id;

    } catch (error) {
      console.error('OKTA keepalive getOktaSessionId', error);
      verbose && console.trace("This stack trace is for the above error", error);
      retryInAMinute();
      return null;
    };
  };

  const refreshOktaSession = async (sessionId) => {
    verbose && console.log('step 2 refreshOktaSession');
    try {
      const response = await axios.post(`${url}/api/v1/sessions/me/lifecycle/refresh`, {}, {
        headers: {
          Accept: 'application/json',
          "Content-Type": 'application/json',
          Cookie: sessionId
        },
        withCredentials: true
      })

      verbose && console.log(response);
      localStorage.setItem(OKTA_SESSION_EXPIRATION, response.data.expiresAt);
      verbose && console.log('expiresAt', response.data.expiresAt);
      return true;
    } catch (error) {
      console.error('OKTA keepalive refreshOktaSession', error);
      verbose && onsole.trace("This stack trace is for the above error", error);
      retryInAMinute();
      return false;
    }
  };

  const retryInAMinute = () => {
    verbose && console.log('retryInAMinute; attempts made:', attempts);
    if (attempts <= 5) {
      console.log('okta-keep-alive trying again in one minute');
      setTimeout(callOktaSession, oneMinute);
      attempts++;
    } else {
      OktaKeepAliveSubject.next({ type: SUBSCRIBERS.NO_MORE_ATTEMPTS, data: { error: 'attempted 5 times, no more attempts will be made until the component is reloaded' } });
    }
  }

  const shouldCallOkta = () => {
    verbose && console.log('shouldCallOkta');
    const expiresAt = localStorage.getItem(OKTA_SESSION_EXPIRATION);
    verbose && console.log('expiresAt', expiresAt);
    if (expiresAt) {
      const expireDate = moment(expiresAt);
      const now = moment();
      const expiresIn = expireDate.diff(now, 'minutes');
      verbose && console.log('expiresIn', expiresIn);
      if (expiresIn < 30) {
        return true;
      }
    } else {
      return true;
    }
    return false;
  }

  useEffect(() => {
    verbose && console.error('** okta-keep-alive verbose IS ENABLED ** THIS IS NOT RECOMMEDNED FOR PRODUCTION DEPLOYMENTS');
    verbose && console.log('oktaUrl', oktaUrl);
    verbose && console.time('okta-keep-alive');
    if (shouldCallOkta() && oktaUrl) {
      try {
        const newUrl = oktaUrl.match(/^(https\:\/\/[^\/?#]+)(?:[\/?#]|$)/i)[1];
        setUrl(newUrl);
        verbose && console.log('url', newUrl);
      } catch (error) {
        console.error('OKTA keepalive on load', error);
        verbose && console.trace("This stack trace is for the above error", error);
      }
    }
  }, [oktaUrl]);

  useEffect(() => {
    if(url) {
      callOktaSession();
    }
  }, [url]);

  return (
    <>{children}</>
  )
}

export default KeepAlive;