import { SearchFilterInterface, IConfigData } from "../components/notification-view/notification.interface";
import { getNotificationsData } from './api-helper'

class NotificationServiceController {
  public notificationData: SearchFilterInterface;
  private accessToken: string;
  private audienceEmail: string;
  private publisher: string;
  private limit: number;
  private offset: number = 0;
  private envConfig: IConfigData;

  async load(category: Array<string>) {
      this.offset = 0;
      this.notificationData = await getNotificationsData(this.envConfig, this.accessToken, this.audienceEmail, category, this.publisher, this.limit, this.offset);
      return this.notificationData;
  }

  getData(category: Array<string>) {
      return this.load(category);
  }
  
  saveUserInfo(accessToken: string, audienceEmail: string, envConfig: IConfigData, publisher: string) {
    this.accessToken = accessToken;
    this.audienceEmail = audienceEmail;
    this.envConfig = envConfig;
    this.publisher = publisher;
    this.limit = 100;
  }
}

export const NotificationService = new NotificationServiceController();