import { SearchFilterInterface, IConfigData } from '../components/notification-view/notification.interface';

function getSearchQuery(category: Array<string>, audienceEmail: string, publisher: string, limit: number, offset: number) {
  let valueFilter = category.map(x => {
    return `{ valueFilter: { field:"category=${x}" } }`;
  });
  let compositeFilter = `{ logicOperator: AND 
    subFilters: [ 
      { 
        logicOperator: OR 
        
        subFilters: [ 
          { valueFilter: { field:"channels=INBOX" } } 
        ] 
      },
      { logicOperator: OR 
        subFilters: [ ${valueFilter.join(',')} ] 
      }, 
      { logicOperator: OR 
        subFilters: [ 
          { valueFilter: { field:"audience.audienceList=${audienceEmail}\" }  },
          { valueFilter: { field:"audience.audienceList=${audienceEmail.toLowerCase()}\" }  },
          { valueFilter: { field:"audience.properties.targetEmails.toList=${audienceEmail}\" }  },
          { valueFilter: { field:"audience.properties.targetEmails.toList=${audienceEmail.toLowerCase()}\" }  },
        ] 
      },
      { logicOperator: OR 
        subFilters: [ 
          { valueFilter: { field:"publisher=${publisher}\" } } 
        ] 
      }
      {
        logicOperator: OR 
        subFilters: [
          {valueFilter: { field:"notificationState=NEW" }}
        ]
      } 
    ] 
  }`;
  return `{ 
    searchFilter(
      limit: ${limit} 
      offset: ${offset} 
      sortBy: ["publishedAt","_id"] 
      compositeFilter: ${compositeFilter}) 
      {  
        notifications {  
          _id
          notificationState   
          expiryTime
          expireAfter       
        } 
      } 
    }`;
}

export function getNotificationsData(envConfig: IConfigData, accessToken: string, audienceEmail: string, category: Array<string>, publisher: string, limit: number, offset: number): Promise<SearchFilterInterface> | never {
  return fetch(`${envConfig.apiHost}/notification-receiver`, {
        method: 'POST',
        headers: {
            Authorization: `${accessToken}`,
            'x-api-key': `${envConfig.apiKey}`,
            psid: envConfig.psid ? `${envConfig.psid}` : '',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query: getSearchQuery(category, audienceEmail, publisher, limit, offset)
          })
    })
    .then(response => {
        return response.json()
    })
    .then(response => {
        return response.data ? response.data.searchFilter : {};
    })
    .catch((err) => {
        console.log(err);
        return null;
    })
}

