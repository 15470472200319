import { NotificationInterface } from "../components";

const IsExpired = (data: NotificationInterface): boolean => {
  // eslint-disable-next-line @stencil-community/strict-boolean-conditions
  if (data.expiryTime) {
    const expiryTime = new Date(+data.expiryTime * 1000);
    const expireAfter = data.expireAfter ?? 0;
    const expireAfterDate = expiryTime.setDate(expiryTime.getDate() + expireAfter);
    if (expireAfterDate < new Date().getTime()) {
      return true;
    }
  }
  return false;
};

export default IsExpired;
